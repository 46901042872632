@import "nprogress/nprogress.css";

/*
  The following styles are copied from the stylesheet imported
  above with all colors overridden using Tailwind.

  todo: is there a less hacky way to style this?
*/

#nprogress .bar {
  background: currentColor;
}

#nprogress .peg {
  box-shadow: 0 0 10px currentColor, 0 0 5px currentColor;
}

#nprogress .spinner-icon {
  border-top-color: currentColor;
  border-left-color: currentColor;
}
